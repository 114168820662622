.contact {
  margin-top: 10px;
}

.contact-column {
  @include make-sm-column(12);
}

.contact-firstname,
.contact-lastname {
  @include make-sm-column(6);
}

.contact-plz {
  @include make-sm-column(4);
}

.contact-city {
  @include make-sm-column(8);
}

@media (min-width: $screen-sm) {
  .contact-firstname,
  .contact-plz {
    padding-right: 0;
  }
}

.contact-checkbox {
  width: 25px;
  float: left;
  border: none;
  box-shadow: none;
}

.contact-submit {
  @include make-md-column(6);
}

.contact-confirmation,
.contact-error {
  display: none;
  border: 0;
  border-radius: 0;

  &.alert-success {
    background-color: $brand-primary;
    color: $color-white;
  }

  b {
    font-weight: bold;
  }

  .debug {
    font-size: $font-size-small;
  }
}

#map {
  margin-top: 50px;
}
