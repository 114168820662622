.neos-nodetypes-image {
  &.line-splitted-bottom img {
    background: transparent url('../Images/layout/line-splitted-green.svg') bottom center repeat-x;
    padding-bottom: 20px;
    background-size: 12px 7px;
  }
}

.neos-nodetypes-text {
   &.line-splitted-bottom {
    background: transparent url('../Images/layout/line-splitted-green.svg') bottom center repeat-x;
    padding-bottom: 20px;
    background-size: 12px 7px;
  }

  &.circle-orange {
    display: table-cell;;
    vertical-align: middle;
    border-radius: 50%;
    height: 165px;
    width: 165px;
    background-color: $brand-primary;
    color: #fff;
    font-weight: $font-weight-light;

    p {
      margin: 0;
      text-align: center;
      padding: 10px;
    }

    a {
      color: #fff;
      font-weight: $font-weight-semibold;
      text-decoration: underline;
    }
  }
  
  &.larger {
    height: 200px;
    width: 200px;
  }
  
  &.arrow {
    position: relative;
    &::after {
      content: url('../Images/layout/casanaspa/button-arrow-right-white.svg');
      width: 18px;
      height: 18px;
      position: absolute;
      left: 50%;
      bottom: 16px;
      transform: translateX(-50%);
      display: inline-block;
    }
  }
}

.neos-nodetypes-image,
.neos-nodetypes-textwithimage {
  &.img-circle {
    figure > img {
      @extend .img-circle;
      margin-top: 35px;
      margin-bottom: 35px;
      width: 165px;
      height: 165px;
    }
  }
}
