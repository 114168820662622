.more {

  .more-content {
    position: relative;
  }


  i.more-icon {
    position: absolute;
    width: 25px;
    height: 25px;
    background: $button-arrow-right no-repeat;
    background-position: 0 2px;
    background-size: 20px;
    padding-left: 30px;
    text-decoration: none;
  }

  p.more-link a {
    margin-left: 35px;
    color: $color-black;
    font-weight: $font-weight-semibold;
  }
}
