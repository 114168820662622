$header-logo-height-md: 100px;
$header-logo-height-xs: $header-logo-height-md/2;

@import "../themes/_header_base";
@include header-base;

.header {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 20;
  background-color: transparentize($color-black, 0.1);

  @media (min-width: $screen-md-min) {
    background-color: transparentize($color-black, 0.2);
  }

  body.scroll & {
    min-height: 120px;
  }
}

.header-logo-wrapper {
  width: 100%;
  height: 160px;
  background-color: $color-black;

  @media (min-width: $screen-md-min) {
    background-color: transparentize($color-black, 0.7);
  }

  body.scroll & {
    height: 70px;
  }
}

.header-logo-content {
  width: 230px;
  padding-top: 25px;
  height: auto;
  margin: auto;

  body.scroll & {
    padding-top: 0;
  }

  .logo {
    margin: auto;
    background: $logo 0 0 no-repeat;
    width: $logo-width;
    height: $logo-height;

    body.scroll & {
      background: $logo-icon 0 10px no-repeat;
      width: 45px;
      height: 70px;
      background-size: 45px;
    }
  }
}

.header-nav {
  @extend .container;
  font-size: 18px;
  line-height: 1.166666667;
  letter-spacing: 3px;
  text-transform: uppercase;

  a {
    color: darken($color-white, 10%);
    font-weight: $font-weight-light;

    &:hover {
      color: $color-white;
    }
  }
}

.header-nav-toggle {
  position: relative;
  right: 0;

  .icon-bar {
    width: 30px;
    height: 4px;
  }
}

.header-nav-list {
  margin-bottom: 0;

  li {
    @media (max-width: $screen-sm-max) {
      width: 100%;
      text-align: center;
      margin-bottom: 15px;
    }

    @media (min-width: $screen-md) {
      display: inline-block;
      line-height: 50px;

      + li {
        margin-left: 15px;
      }
    }

    @media (min-width: $screen-lg-min) {

      + li {
        margin-left: 30px;
      }
    }

    &.current,
    &.active {
      font-weight: $font-weight-semibold;

      a {
        font-weight: inherit;
      }

      @media (min-width: $screen-md) {
        > .header-nav-sub {
            display: block;
        }
      }
    }

    .header-nav-sub {
      display: none;

      li {
        text-decoration: none;

        a {
          font-weight: normal;
        }

        &.active a {
          font-weight: bold;
        }
      }

      li.current {

        a {
          font-weight: $font-weight-semibold;
          text-decoration: none;
          position: relative;
          
          &::after {
            content: '';
            border-bottom: 1px solid $color-gray-light;
            width: 100%;
            position: absolute;
            bottom: 0;
            left: 0;
          }
        }
      }
    }
  }
}

@media (min-width: $screen-md) {
  .header-nav-sub {
    width: 100%;
    height: 50px;
    background-color: $brand-primary;
    margin: 0;
    @if $site == ekur {
      font-size: $font-size-small;
    }

    @if $site == casanaspa {
      font-size: 18px;
    }

    li {
      padding-right: 5px;

      &.current,
      &.active {
        text-decoration: underline;
      }
    }
  }
}
