@mixin footer-base() {
  .footer-content {
    @extend .container;
  }

  .footer-nav {
    a {
      color: inherit;

      &:hover {
        color: darken($text-color, 15%);
      }
    }
  }

  .footer-nav-list {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }
}
