@import "../themes/_footer_base";
@include footer-base;

.footer {
  @if $site == ekur {
    background-color: $brand-primary;
  }
  @if $site == casanaspa {
    background-color: $color-gray-dark;
  }
  color: $color-white;
  font-size: $font-size-base;
  letter-spacing: 0.4px;
  text-transform: uppercase;
  overflow: hidden;
}

.footer-content {
  margin: 50px auto;
}

.footer-about {
  @include make-md-column(7);
}

.footer-nav {
  @include make-md-column(5);

  a:hover {
    color: inherit;
  }

  li {
    display: inline-block;
    margin-right: 20px;
  }
}
