.carousel.callout {
  @media (max-width: $screen-xs-max) {
    margin-top: 214px;

    .item {
      // background-size: cover;
      background-repeat: no-repeat;
      background-color: $color-white;
      max-width: 100%;
      margin: 0 auto;
      overflow: hidden;
      height: 175px;

      &.full {
        height: 195px;
      }
    }

    .carousel-indicators {
      display: none;
    }
  }
}

.callout {

  .item {
    height: 700px;
    background-size: cover;
    background-repeat: no-repeat;
    background-color: $color-white;
    max-width: 2000px;
    margin: 0 auto;
    overflow: hidden;

    &.full {
      height: calc(135vh);

      &::after {
        content: '';
        display: none;
        position: absolute;

        @media (min-height: 800px) {
          display: block;
        }
      }

      &::after {
        bottom: 115px;
        left: 50%;
        margin-left: -18px;
        background: transparent $button-arrow-down top center no-repeat;
        background-size: 36px 127px;
        width: 36px;
        height: 127px;
        z-index: 205;
      }
    }
  }

  .carousel-control {
    background-image: none;
  }

  .carousel-control-icon {
    background-repeat: no-repeat;
    background-size: 45px;
    transition: opacity .1s;
    opacity: .5;

    &:hover {
      opacity: 1;
    }

    &.left {
      background-image: url("../Images/layout/callout-arrow-left.svg");
      background-position: 20% 48%;
    }

    &.right {
      background-image: url("../Images/layout/callout-arrow-right.svg");
      background-position: 70% 48%;
    }
  }
}

.carousel-indicators {
  bottom: 15%;
  right: 30px;
  left: auto;
  width: 10%;

  @media (max-width: $screen-sm-max) {
    left: auto;
    right: 30px;
    bottom: 215px;
  }

  @media (max-width: $screen-md-max) {
    left: auto;
    bottom: 20%;
  }

  li {
    border: none;
    background-color: #fff;
    margin-right: 7px;
  }

  .active {
    margin-right: 7px;
    background-color: $brand-primary;
  }
}

.callout-caption {
  position: relative;
  height: 100%;
  @extend .container;
  padding-left: 0;
  
  
  @media (max-width: $screen-md) {
    padding-left: 15px;
  }
}

.callout-caption-box {
  display: none;
  color: $color-white;


  @media (min-width: $screen-sm-min) {
    display: block;
    position: relative;
    width: 580px;
    height: 230px;
    top: 450px;
    padding: 20px;
    transform: translateY(-50%);
    @if $site == ekur {
      background-color: transparentize($color-black, 0.3);
    }

    @if $site == casanaspa {
      background-color: transparentize($brand-primary, 0.3);
    }
    border-radius: 0 0 55% 50% / 0 0 30% 10%;

    &:hover {
      color: #fff;
    }
  }

  h2 {
    font-size: 26px;
    font-weight: 700;
    text-transform: uppercase;
    line-height: 1.4;
    letter-spacing: 2px;
    color: inherit;
    margin-top: 15px;
  }

  p {
    font-size: 22px;
    font-weight: 300;
    line-height: 1.4;
    color: inherit;
    text-transform: none;
    
    &:last-child {
      display: inline-block;
      position: relative;
            
      &::after {
        content: url('../Images/layout/casanaspa/button-arrow-right-white.svg');
        width: 18px;
        height: 18px;
        position: absolute;
        display: inline-block;
        margin-left: 8px;
        margin-top: 1px;        
      }
    }
  }
}
