.cookiebanner {
  width: 100%;

  @media(min-width: $screen-lg-min) {
    line-height: 32px !important;
  }

  span {
    display: inline-block;
    width: 90%;
  }

  .cookiebanner-close {
    margin: 0 auto;

    @media(min-width: $screen-md-min) {
      float: right;
      margin-top: 15px;
    }

    @media(min-width: $screen-lg-min) {
      float: right;
      margin-top: 0;
    }
  }
}
