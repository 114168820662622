//
// Bootstrap
//
// http://getbootstrap.com/customize/#less-variables
// https://github.com/twbs/bootstrap-sass/blob/v3.3.1/assets/stylesheets/bootstrap/_variables.scss
//

// Grid
$grid-gutter-width: 30px;
$container-large-desktop: (1140px + $grid-gutter-width);
$grid-float-breakpoint: 992px;

// Fonts
$font-family-sans-serif: "Calluna-Sans", sans-serif;
$font-size-base: 17px;
$line-height-base: 1.5;
$font-size-h1: 20px;
$font-size-h2: $font-size-base;
$font-size-h3: $font-size-base;
$font-size-h4: $font-size-base;
$font-size-h5: $font-size-base;
$font-weight-light: 300;
$font-weight-semibold: 700;
$base-font-weight: $font-weight-light;
$headings-font-weight: $font-weight-semibold;
$headings-line-height: 1.5;

// Buttons
$button-arrow-down: url('../Images/layout/button-arrow-down.svg');
$button-arrow-right: url('../Images/layout/button-arrow-right.svg');

@if $site == casanaspa {
  $button-arrow-down: url('../Images/layout/casanaspa/button-arrow-down.svg');
  $button-arrow-right: url('../Images/layout/casanaspa/button-arrow-right.svg');
}

// Colors
$color-red: #be1622;
$color-orange: #f0982c;
$color-black: #000000;
$color-gray-light: #eeeff0;
$color-gray-dark: #676767;
$color-white: #ffffff;
$brand-primary: $color-red;

// Logo
$logo: url("../Images/layout/logo-carlos-schmitt.svg");
$logo-icon: url("../Images/layout/logo-icon-carlos-schmitt.svg");
$logo-width: 230px;
$logo-height: 120px;

@if $site == casanaspa {
  $logo: url("../Images/layout/casanaspa/logo-casana-spa.svg");
  $logo-icon: url("../Images/layout/casanaspa/logo-icon-casana-spa.svg");
  $logo-width: 190px;
  $logo-height: 120px;
}

@if $site == casanaspa {
  $brand-primary: $color-orange;
}

$brand-secondary: $color-black;
$text-color: $color-black;
$link-color: $brand-primary;
$link-hover-color: darken($link-color, 10%);
$link-hover-decoration: none;

// Components
$border-radius-base: 0;
$well-border: transparent;
$well-bg: $brand-secondary;
$well-color: $color-white;
$input-border-focus: $brand-primary;

// Animations
$animation-duration-normal: 500ms;

// Shadows
$shadow-color: lighten($color-black, 50%);
$shadow-img: -3px 2px 4px $shadow-color;
