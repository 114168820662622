.grid-content {
  h1, h2 {
    text-align: left;
  }

  h1 {
    margin-top: 10px;
  }

  p {
    text-align: left;

    &:first-child {
      margin-top: 10px;
    }
  }

  figcaption p {
    text-align: left;
    padding-top: 8px;
  }

  &.first {
    margin-top: 50px;
  }

  &.margin {
    margin-top: 45px;
  }

  &.bg-gray-light {
    background-color: $color-gray-light;
    padding-top: 35px;
    margin-bottom: 35px;
    min-height: 415px;
  }

  &.planung {
    background-image: url('../Images/pages/bad/planung-bg-image.png'), url('../Images/pages/bad/planung-bg-image-rounded-01.png'), url('../Images/pages/bad/planung-bg-image-rounded-02.png');
    background-repeat: no-repeat;

    @media (max-width: $screen-xs-max) {
      background-image: none;
    }

    @media (max-width: $screen-sm-max) {
      background-image: none;
    }

    @media (min-width: $screen-md-min) {
      background-size: 270px, 150px, 150px;
      background-position: left bottom, right 20px bottom 225px, right 20px bottom 50px;
    }

    @media (min-width: $screen-lg-min) {
      background-size: 450px, 150px, 150px;
      background-position: left bottom, right 120px top 80px, right 120px top 240px;
    }
  }

  &.solar {
    background-image: url('../Images/pages/solar/solar-bg-image.png'), url('../Images/pages/solar/solar-bg-image-rounded-01.png'), url('../Images/pages/solar/solar-bg-image-rounded-02.png');
    background-repeat: no-repeat;

    @media (max-width: $screen-xs-max) {
      background-image: none;
    }

    @media (max-width: $screen-sm-max) {
      background-image: none;
    }

    @media (min-width: $screen-md-min) {
      background-size: 270px, 150px, 150px;
      background-position: left bottom, right 20px bottom 225px, right 20px bottom 50px;
    }

    @media (min-width: $screen-lg-min) {
      background-size: 450px, 150px, 150px;
      background-position: left bottom, right 120px top 80px, right 120px top 240px;
    }
  }

  &.fernwaerme {
    background-image: url('../Images/pages/heizung/fernwaerme.png');
    background-repeat: no-repeat;

    @media (max-width: $screen-xs-max) {
      background-image: none;
    }

    @media (max-width: $screen-sm-max) {
      background-image: none;
    }

    @media (min-width: $screen-md-min) {
      background-size: 270px, 150px, 150px;
      background-position: left bottom, right 20px bottom 225px, right 20px bottom 50px;
    }

    @media (min-width: $screen-lg-min) {
      background-size: 450px, 150px, 150px;
      background-position: left bottom, right 120px top 80px, right 120px top 240px;
    }
  }

  .thumbnail-img {
    margin-bottom: 35px;
  }
}

.grid-content-col {
  &.col-12 {
    p {
      max-width: 500px;
      margin-left: auto;
      margin-right: auto;

      @media (min-width: $screen-md-min) {
        max-width: 700px;
      }
    }
  }
}
