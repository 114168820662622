body {
  position: relative;

  > .content {
    // @extend .container;
    margin: 0 auto;
    min-height: calc(100vh);

    @if $site == casanaspa {
      padding-bottom: 50px;
    }

    @media (max-width: $screen-xs-max) {
      margin: 0 auto 0;
    }

    img {
      @extend .img-responsive;
    }

    ul {
      padding-left: 20px;
    }

    table {
      @extend .table;
    }
  }
}

section {
  @extend .container;
}

section.full {
  width: 100%;
}

body {
  font-weight: $base-font-weight;
  letter-spacing: 0.05px;
}

strong, b {
  font-weight: $font-weight-semibold;
}

h1 {
text-transform: uppercase;
}

h2 {
  margin-top: 10px;
}

h3 {
  color: $brand-primary;
  font-weight: $font-weight-light;
}

h3, h4, h5 {
  line-height: $line-height-base;
}
