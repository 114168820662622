.headstart-nodetypes-dividerimage {
  background-size: cover;
  background-repeat: no-repeat;
  background-color: $color-white;
  width: 100%;
  margin: 25px auto 0;
  overflow: hidden;
  height: 130px;

  @media (min-width: $screen-md-min) {
    height: 350px;
  }

  @media (min-width: $screen-lg-min) {
    height: 450px;
  }
}
